<template>
  <section class="page-concept">
    <div class="banner">
      <p class="title">ENTERPRISE CONCEPT</p>
    </div>
    <div class="content">
      <p class="title">
        <span class="van-hairline--bottom">Cultural Concept</span>
      </p>
      <div class="each">
        <p class="keyword">
          <span class="en">PURPOSE</span>
        </p>
        <p class="desc-en">
          Open up innovative ways for High Net Worth Individual around the
          world, covering cross-border property purchases and global asset
          allocation, improvements of higher customer satisfaction within
        </p>
      </div>
      <div class="each">
        <p class="keyword">
          <span class="en">POSITION</span>
        </p>
        <p class="desc-en">
          Steady international asset investment group with sustainable
          value-creating
        </p>
      </div>
      <div class="each">
        <p class="keyword">
          <span class="en">SLOGAN</span>
        </p>
        <p class="desc-en">
          Building a better world with us
        </p>
      </div>
      <div class="each">
        <p class="keyword">
          <span class="en">CORE VALUES</span>
        </p>
        <p class="desc-en">
          Proud to embody value, where different legacies and advantages are
          merged and empowered. Any innovation, collaboration and efficiency
          should be oriented towards responsibility and results. Corporately we
          work, collectively we share, a fair principle of value distribution
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Cultural Concept";
  }
};
</script>

<style lang="less" scoped>
.page-concept {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 158px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/about/concept-banner.jpg")
      no-repeat center center / cover;
    .title {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .content {
    padding: 20px 22px 16px;
    .title {
      padding-bottom: 36px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .each {
      margin-bottom: 36px;
      .keyword {
        .en {
          font-size: 18px;
          font-weight: bold;
          color: rgba(38, 38, 38, 1);
        }
      }
      .desc-en {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }
    }
  }
}
</style>
